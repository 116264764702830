import React from "react"

import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"
import Submenutreneri from "../../components/submenutreneri/submenutreneri"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import "./../treneri.scss"

import ImgTreneriPixa from "../../images/treneri/jiri-pixa.jpg"

const JiriPixa = () => (

	<div>

		<Layout>
			<Seo title="Jiří Pixa" description="Jiří Pixa" />

			<div className="stk-container stk-container--top-margin">

				<Row>
					<Col xs="12" xl="9">
						<div class="stk-trener__header">
							<div>
								<img class="stk-trener__image" src={ImgTreneriPixa} alt="Jiří Pixa" />
							</div>
							<div>
								<h1 className="stk-h1">Jiří Pixa</h1>
								<p>Trenér a porotce 1. třídy - standardní a latinsko-americké tance</p>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--facebook" href="https://www.facebook.com/profile.php?id=100000877298501" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faFacebookF} />
								</a>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--phone" href="tel:+420602387109" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faPhone} />
								</a>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--email" href="mailto:jj.pixa@seznam.cz" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faEnvelope} />
								</a>
							</div>
						</div>
						<ul>
							<li>Absolvent Trenérské školy FTVS Univerzity Karlovy v Praze</li>
							<li>TOP porotce ČSTS</li>
							<li>Porotce s licencí WDSF</li>
							<li>Trenér standardních tanců</li>
							<li>Trenér latinsko-amerických tanců</li>
							<li>Trenér PRO-AM</li>
							<li>Finalista Mistrovství ČR ve Standardu a 7. místo MR Latina.</li>
							<li>Vedoucí klubu STK Praha</li>
						</ul>
					</Col>
					<Col xs="12" xl="3">
						<Submenutreneri></Submenutreneri>
					</Col>
				</Row>
			</div>
		</Layout>

	</div>

)

export default JiriPixa